import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, HostListener, PLATFORM_ID, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { MobileOffcanvasComponent } from '../../../components/mobile-offcanvas/mobile-offcanvas.component';
import { UtilsService } from '../../../services/utils.service';

@Component({
    selector: 'app-header-three',
    standalone: true,
    imports: [CommonModule, RouterModule, MobileOffcanvasComponent],
    templateUrl: './header-three.component.html',
    styleUrl: './header-three.component.css',
})
export class HeaderThreeComponent {
    private readonly platformId = inject(PLATFORM_ID);

    constructor(public utilsService: UtilsService, private router: Router) {}

    scrollToFragment(fragment: string): void {
        if (isPlatformBrowser(this.platformId)) {
            setTimeout(() => {
                const element = document.getElementById(fragment);
                if (element) {
                    const offsetTop = element.offsetTop - 60;
                    console.log('element offset', element, offsetTop);
                    window.scrollTo({ top: offsetTop, behavior: 'smooth' });
                }
            }, 100);
        }
    }

    navigateWithOffset(fragment: string): void {
        this.router.navigate(['/landing'], { fragment: fragment });
        this.scrollToFragment(fragment);
    }

    isFeatureRouteActive(): boolean {
        return this.router.url === '/landing#feature';
    }

    isRoadMapRouteActive(): boolean {
        return this.router.url === '/landing#roadMap';
    }

    public sticky: boolean = false;
    // sticky nav
    @HostListener('window:scroll', ['$event']) onscroll() {
        if (window.scrollY > 100) {
            this.sticky = true;
        } else {
            this.sticky = false;
        }
    }
}
