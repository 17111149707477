<div class="mobile-menu">
    <nav class="menu-box">
        <div class="close-btn" (click)="utilsService.openMobileMenus = !utilsService.openMobileMenus">
            <i class="fas fa-times"></i>
        </div>
        <div class="nav-logo">
            <a routerLink="/landing">
                <img src="assets/img/logo/logo.png" style="height: 35px" alt="Logo" />
            </a>
        </div>
        <div class="menu-outer">
            <ul class="navigation">
                <li class="menu-item-has-children" [routerLinkActive]="['active']">
                    <a class="section-link" routerLink="/landing">Home</a>
                </li>

                <li class="menu-item-has-children" [routerLinkActive]="['active']">
                    <a routerLink="/blog">blog</a>
                </li>
                <li [routerLinkActive]="['active']">
                    <a routerLink="/contact">Contact</a>
                </li>
            </ul>
        </div>
        <div class="social-links">
            <ul class="clearfix list-wrap">
                <li>
                    <a href="https://x.com/mirailab_io" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path
                                d="M10.0596 7.34522L15.8879 0.570312H14.5068L9.44607 6.45287L5.40411 0.570312H0.742188L6.85442 9.46578L0.742188 16.5703H2.12338L7.4676 10.3581L11.7362 16.5703H16.3981L10.0593 7.34522H10.0596ZM8.16787 9.54415L7.54857 8.65836L2.62104 1.61005H4.74248L8.71905 7.29827L9.33834 8.18405L14.5074 15.5779H12.386L8.16787 9.54449V9.54415Z"
                                fill="currentColor"
                            ></path>
                        </svg>
                    </a>
                </li>
                <li>
                    <a href="https://www.instagram.com/mirailab.io" target="_blank"><i class="fab fa-instagram"></i></a>
                </li>
            </ul>
        </div>
    </nav>
</div>
<div class="menu-backdrop" (click)="utilsService.openMobileMenus = !utilsService.openMobileMenus"></div>
