import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { of, switchMap } from 'rxjs';
import { BlogSidebarComponent } from '../../shared/components/blog/blog-sidebar/blog-sidebar.component';
import { BreadcrumbComponent } from '../../shared/components/breadcrumb/breadcrumb.component';
import { DocumentAreaComponent } from '../../shared/components/document-area/document-area.component';
import { FooterThreeComponent } from '../../shared/layout/footer/footer-three/footer-three.component';
import { HeaderThreeComponent } from '../../shared/layout/header/header-three/header-three.component';
import { BlogApiService } from '../../shared/services/api/apis/blog.service';
import { UtilsService } from '../../shared/services/utils.service';
import { PostBlog } from '../../shared/types/blog-d-t';

@Component({
    selector: 'app-blog-details',
    standalone: true,
    imports: [CommonModule, FormsModule, RouterModule, HeaderThreeComponent, BreadcrumbComponent, DocumentAreaComponent, BlogSidebarComponent, FooterThreeComponent],
    templateUrl: './blog-details.component.html',
    styleUrls: ['./blog-details.component.css'],
})
export class BlogDetailsComponent {
    public blog: PostBlog | null | undefined;
    public related_blogs: PostBlog[] = [];
    private topic: string = 'ai';

    constructor(private route: ActivatedRoute, public utilsService: UtilsService, private router: Router, private blogApiService: BlogApiService) {}

    ngOnInit() {
        this.route.paramMap
            .pipe(
                switchMap((params) => {
                    let blogId: any = params.get('id');
                    if (blogId) {
                        const blogById = this.blogApiService.getPostById(blogId, this.topic);
                        return blogById;
                    }
                    return of<PostBlog | null>(null); // Emit null if there's no blogId
                }),
            )
            .subscribe((blog: PostBlog | null) => {
                if (blog) {
                    this.blog = blog;
                } else {
                    // blog not found, navigate to 404 page
                    this.router.navigate(['/notfound']);
                }
            });
    }
}
