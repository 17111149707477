import { Routes } from '@angular/router';
import { BlogDetailsResolver } from './blog.guard';
import { LandingComponent } from './landing/landing.component';
import { BlogDetailsComponent } from './pages/blog-details/blog-details.component';
import { BlogComponent } from './pages/blog/blog.component';
import { ContactComponent } from './pages/contact/contact.component';

export const routes: Routes = [
    {
        path: '',
        children: [{ path: '', redirectTo: '/landing', pathMatch: 'full' }],
    },
    {
        path: 'landing',
        children: [{ path: '', component: LandingComponent }],
    },
    { path: 'blog', component: BlogComponent, title: 'Blog - Mirai' },
    {
        path: 'blog-details/:id',
        component: BlogDetailsComponent,
        title: 'Blog Details - Mirai',
        resolve: { blog: BlogDetailsResolver },
    },
    { path: 'contact', component: ContactComponent, title: 'Contact - Mirai' },
    {
        path: 'notfound',
        loadChildren: () => import('./shared/components/notfound/notfound.routes'),
    },
    {
        path: '**',
        redirectTo: 'notfound',
    },
];
