import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PostBlog } from '../../../types/blog-d-t';

@Component({
    selector: 'app-blog-single',
    standalone: true,
    imports: [RouterModule],
    templateUrl: './blog-single.component.html',
    styleUrl: './blog-single.component.css',
})
export class BlogSingleComponent {
    @Input() blog!: PostBlog;
}
