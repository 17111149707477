<div class="blog-post">
    <div class="blog-thumb">
        <a [routerLink]="'/blog-details/' + blog.id">
            <img [src]="blog.thumbnail" alt="" />
        </a>
    </div>
    <div class="blog-content">
        <div class="blog-meta">
            <ul class="list-wrap">
                <li><i class="far fa-clock"></i>{{ blog.date }}</li>
                <li>
                    <a [routerLink]="'/blog-details/' + blog.id">
                        <i class="far fa-comment"></i>
                        {{ blog.comments.length > 9 ? blog.comments.length : '0' + blog.comments.length }}
                    </a>
                </li>
                <li><i class="far fa-eye"></i>{{ blog.view }}Viewers</li>
            </ul>
        </div>
        <h2 class="title">
            <a [routerLink]="'/blog-details/' + blog.id">{{ blog.title }}</a>
        </h2>
        <p>{{ blog.description }}..</p>
        <div class="content-bottom">
            <div class="blog-author">
                <a [routerLink]="'/blog-details/' + blog.id">
                    <img [src]="blog.author" alt="" />
                    {{ blog.author }}
                </a>
            </div>
            <div class="read-more-btn">
                <a [routerLink]="'/blog-details/' + blog.id">Read More<i class="fas fa-arrow-right"></i></a>
            </div>
        </div>
    </div>
</div>
