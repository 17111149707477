import { isPlatformBrowser } from '@angular/common';
import { Component, PLATFORM_ID, inject } from '@angular/core';

@Component({
    selector: 'app-back-to-top',
    standalone: true,
    imports: [],
    templateUrl: './back-to-top.component.html',
    styleUrl: './back-to-top.component.css',
})
export class BackToTopComponent {
    private readonly platformId = inject(PLATFORM_ID);

    ngOnInit() {
        if (isPlatformBrowser(this.platformId)) {
            const result = document.querySelector('.scroll-to-target');
            if (result) {
                document.addEventListener('scroll', () => {
                    if (window.scrollY > 200) {
                        result.classList.add('open');
                    } else {
                        result.classList.remove('open');
                    }
                });
                result.addEventListener('click', () => {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                });
            }
        }
    }
}
