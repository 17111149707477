<div class="home-purple-gradient" [class.mobile-menu-visible]="utilsService.openMobileMenus">
    <!-- header area start -->
    <app-header-one />
    <!-- header area end -->

    <div class="fullscreen-container">
        <div id="header">
            <p-toolbar id="toolbar-header">
                <div class="p-toolbar-group-left">
                    <h1 class="logoName">Mirai</h1>
                </div>

                <div class="p-toolbar-group-center"></div>

                <div class="p-toolbar-group-right">
                    <a href="https://play.google.com/store/apps/details?id=daiware.dpupps.com" target="_blank">
                        <div class="box">
                            <img class="avatar" src="../../assets/img/landing/girl2-avatar.png" />
                            <div class="box-platform">
                                <img src="../../assets/img/landing/android.png" />
                            </div>
                            <span class="nameBox"> Mirai </span>
                        </div>
                    </a>
                    <a href="https://apps.apple.com/us/app/aiware-ai-girl-virtual-friend/id6471303287" target="_blank">
                        <div class="box">
                            <img class="avatar" src="../../assets/img/landing/girl2-avatar.png" />
                            <div class="box-platform">
                                <img src="../../assets/img/landing/apple.png" />
                            </div>
                            <span class="nameBox"> MiraiAI </span>
                        </div>
                    </a>
                </div>
            </p-toolbar>
        </div>

        <h3 class="title-shadow">
            <span class="separator1">YOUR PERFECT</span>
            <span class="separator2">VIRTUAL FRIEND</span>
        </h3>

        <div>
            <img src="../../../assets/img/landing/main.png" class="imgMain" />
        </div>

        <a href="https://mirailab.io" target="_blank">
            <button pButton pRipple class="startChat" label="Start to Chat"></button>
        </a>

        <div>
            <img src="../../../assets/img/landing/details.png" class="imgDetails" />
        </div>

        <div class="terms">
            <a href="https://mirailab.io/doc/terms" target="_blank"><b>Terms of service</b></a>
            ·
            <a href="https://mirailab.io/doc/privacy" target="_blank"><b>Privacy policy</b></a>
            ·
            <b>Copyright © Mirai, All rights reserved.</b>
        </div>
    </div>
</div>
