<header id="header" class="header-layout1">
    <div id="sticky-header" class="menu-area transparent-header" [class.sticky-menu]="sticky">
        <div class="container custom-container">
            <div class="row">
                <div class="col-12">
                    <div class="menu-wrap">
                        <nav class="menu-nav">
                            <div class="navbar-wrap main-menu d-none d-lg-flex">
                                <ul class="navigation">
                                    <li class="menu-item-has-children" [routerLinkActive]="['active']">
                                        <a class="section-link" routerLink="/landing">Home</a>
                                    </li>
                                    <li class="menu-item-has-children" [routerLinkActive]="['active']">
                                        <a routerLink="/blog">Blog</a>
                                    </li>
                                    <li [routerLinkActive]="['active']">
                                        <a routerLink="/contact">Contact</a>
                                    </li>
                                </ul>
                            </div>

                            <div (click)="utilsService.openMobileMenus = !utilsService.openMobileMenus" class="mobile-nav-toggler">
                                <i class="fas fa-bars"></i>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- mobile offcanvas menu -->
    <app-mobile-offcanvas />
    <!-- mobile offcanvas menu -->
</header>
