import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom, isDevMode } from '@angular/core';
import { provideClientHydration, withNoHttpTransferCache } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { InMemoryScrollingFeature, InMemoryScrollingOptions, provideRouter, withInMemoryScrolling } from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { provideLottieOptions } from 'ngx-lottie';
import { routes } from './app.routes';
import { ApiService } from './shared/services/api/api.service';
import { BlogApiService } from './shared/services/api/apis/blog.service';

const scrollConfig: InMemoryScrollingOptions = {
    scrollPositionRestoration: 'top',
    anchorScrolling: 'enabled',
};
const inMemoryScrollingFeature: InMemoryScrollingFeature = withInMemoryScrolling(scrollConfig);

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes, inMemoryScrollingFeature),
        provideAnimations(),
        provideLottieOptions({
            player: () => import('lottie-web'),
        }),
        provideServiceWorker('ngsw-worker.js', {
            enabled: !isDevMode(),
            registrationStrategy: 'registerWhenStable:30000',
        }),
        provideClientHydration(
            // withHttpTransferCacheOptions({
            //     includePostRequests: true,
            // }),
            withNoHttpTransferCache(),
        ),
        provideHttpClient(withFetch()),
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        importProvidersFrom(NgxGoogleAnalyticsModule.forRoot('G-D7632EM098')),
        importProvidersFrom(NgxGoogleAnalyticsRouterModule),
        ApiService,
        BlogApiService,
    ],
};
