import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { PostBlog } from '../../../types/blog-d-t';
import { ApiService } from '../api.service';

@Injectable({
    providedIn: 'root',
})
export class BlogApiService {
    constructor(private apiService: ApiService) {}

    getPostsBlogApi(topic: string): Observable<PostBlog[]> {
        const body = {
            topic: topic,
        };

        return this.apiService.postRequest<PostBlog[]>('blog/posts/get', body).pipe(
            map((response) => {
                return response || [];
            }),
        );
    }

    getPostById(id: string, topic: string): Observable<PostBlog | null> {
        const body = {
            id: id,
            topic: topic,
        };

        return this.apiService.postRequest<PostBlog>(`blog/post/get`, body).pipe(
            map((response) => {
                return response || null;
            }),
        );
    }
}
