import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PrimeNGModule } from './primeng.module';

@NgModule({
    declarations: [],
    imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, PrimeNGModule],
    exports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, PrimeNGModule],
    providers: [],
})
export class SharedModule {}
