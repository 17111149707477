import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { BreadcrumbComponent } from '../../shared/components/breadcrumb/breadcrumb.component';
import { DocumentAreaComponent } from '../../shared/components/document-area/document-area.component';
import { FooterThreeComponent } from '../../shared/layout/footer/footer-three/footer-three.component';
import { HeaderThreeComponent } from '../../shared/layout/header/header-three/header-three.component';
import { UtilsService } from '../../shared/services/utils.service';

@Component({
    selector: 'app-contact',
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, HeaderThreeComponent, BreadcrumbComponent, DocumentAreaComponent, FooterThreeComponent],
    templateUrl: './contact.component.html',
    styleUrl: './contact.component.css',
})
export class ContactComponent {
    constructor(public utilsService: UtilsService) {}

    public contactForm!: FormGroup;
    public formSubmitted = false;

    ngOnInit() {
        this.contactForm = new FormGroup({
            name: new FormControl(null, Validators.required),
            email: new FormControl(null, [Validators.required, Validators.email]),
            phone: new FormControl(null, Validators.required),
            company: new FormControl(null, Validators.required),
            message: new FormControl(null, Validators.required),
        });
    }

    onSubmit() {
        this.formSubmitted = true;
        if (this.contactForm.valid) {
            console.log('contact-form-value', this.contactForm.value);
            alert(`Message sent successfully`);

            // Reset the form
            this.contactForm.reset();
            this.formSubmitted = false; // Reset formSubmitted to false
        }
        console.log('contact-form', this.contactForm);
    }

    get name() {
        return this.contactForm.get('name');
    }
    get email() {
        return this.contactForm.get('email');
    }
    get phone() {
        return this.contactForm.get('phone');
    }
    get company() {
        return this.contactForm.get('company');
    }
    get message() {
        return this.contactForm.get('message');
    }

    public info_data = [
        // {
        //     icon: 'fas fa-map-marker-alt',
        //     title: 'Location',
        //     description: '-',
        // },
        // {
        //     icon: 'fas fa-phone-alt',
        //     title: 'Contact',
        //     // description: '<a href="tel:0123456789">+88(0) 555-0108</a> <br> <a href="tel:0123456789">+88(0) 555-01117</a>',
        //     description: '-',
        // },
        {
            icon: 'fas fa-envelope',
            title: 'Email',
            description: '<a href="mailto:contact@mirailab.io">contact&#64;mirailab.io</a>',
        },
    ];
}
