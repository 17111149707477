import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable()
export class ApiService {
    constructor(private http: HttpClient) {}

    postRequest<T>(url: string, body: any): Observable<T> {
        try {
            return this.http.post<T>(`${environment.pathBackend}/${url}`, body);
        } catch (error) {
            console.log('Error in postRequest - url:', url);

            throw error;
        }
    }

    getRequest<T>(url: string): Observable<T> {
        try {
            return this.http.get<T>(`${environment.pathBackend}/${url}`);
        } catch (error) {
            console.log('Error in getRequest - url:', url);
            throw error;
        }
    }
}
