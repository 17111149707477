import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, Input, PLATFORM_ID, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { UtilsService } from '../../services/utils.service';

@Component({
    selector: 'app-mobile-offcanvas',
    standalone: true,
    imports: [CommonModule, RouterModule],
    templateUrl: './mobile-offcanvas.component.html',
    styleUrl: './mobile-offcanvas.component.css',
})
export class MobileOffcanvasComponent {
    @Input() menuTwo: boolean = false;
    public openSubMenu: string = '';
    private readonly platformId = inject(PLATFORM_ID);

    constructor(public utilsService: UtilsService, private router: Router) {}

    scrollToFragment(fragment: string): void {
        if (isPlatformBrowser(this.platformId)) {
            setTimeout(() => {
                const element = document.getElementById(fragment);
                if (element) {
                    const offsetTop = element.offsetTop - 60;
                    console.log('element offset', element, offsetTop);
                    window.scrollTo({ top: offsetTop, behavior: 'smooth' });
                }
            }, 100);
        }
    }

    navigateWithOffset(url: string, fragment: string): void {
        this.router.navigate([url], { fragment: fragment });
        this.scrollToFragment(fragment);
        this.utilsService.openMobileMenus = !this.utilsService.openMobileMenus;
    }

    handleSubMenu = (title: string) => {
        if (this.openSubMenu === title) {
            this.openSubMenu = '';
        } else {
            this.openSubMenu = title;
        }
    };
}
