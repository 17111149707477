import { isPlatformBrowser } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class UtilsService {
    private readonly platformId = inject(PLATFORM_ID);

    constructor(private router: Router) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.openMobileMenus = false;
            }
        });
    }

    // video modal
    public videoUrl: string = 'https://www.youtube.com/embed/';
    public isVideoOpen: boolean = false;
    public openMobileMenus: boolean = false;
    public openOffcanvas: boolean = false;
    public iframeElement: HTMLIFrameElement | null = null;

    // open mobile sidebar
    handleOpenMobileMenu() {
        this.openMobileMenus = !this.openMobileMenus;
    }

    // modal video play
    playVideo(videoId: string) {
        if (isPlatformBrowser(this.platformId)) {
            const videoOverlay = document.querySelector('#video-overlay');
            this.videoUrl = `https://www.youtube.com/embed/${videoId}`;
            if (!this.iframeElement) {
                this.iframeElement = document.createElement('iframe');
                this.iframeElement.setAttribute('src', this.videoUrl);
                this.iframeElement.style.width = '60%';
                this.iframeElement.style.height = '80%';
            }

            this.isVideoOpen = true;
            videoOverlay?.classList.add('open');
            videoOverlay?.appendChild(this.iframeElement);
        }
    }
    // close modal video
    closeVideo() {
        if (isPlatformBrowser(this.platformId)) {
            const videoOverlay = document.querySelector('#video-overlay.open');

            if (this.iframeElement) {
                this.iframeElement.remove();
                this.iframeElement = null;
            }

            this.isVideoOpen = false;
            videoOverlay?.classList.remove('open');
        }
    }
}
