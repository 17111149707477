import { Component } from '@angular/core';
import { HeaderOneComponent } from '../shared/layout/header/header-one/header-one.component';
import { HeaderThreeComponent } from '../shared/layout/header/header-three/header-three.component';
import { UtilsService } from '../shared/services/utils.service';
import { SharedModule } from '../shared/shared.module';

@Component({
    selector: 'landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss'],
    standalone: true,
    imports: [SharedModule, HeaderThreeComponent, HeaderOneComponent],
})
export class LandingComponent {
    constructor(public utilsService: UtilsService) {
        console.log('HomeMainComponent');
    }
}
