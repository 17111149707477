<ul class="list-wrap" *ngIf="paginate.pages && paginate.pages.length">
    <li [ngClass]="{ disabled: paginate.currentPage === 1 }">
        <a (click)="pageSet(paginate.currentPage - 1)" class="pointer">
            <i class="fas fa-arrow-left"></i>
        </a>
    </li>
    <li *ngFor="let page of paginate.pages" [ngClass]="{ active: paginate.currentPage == page }">
        <a class="pointer" (click)="pageSet(page)">{{ page }}</a>
    </li>
    <li [ngClass]="{ disabled: paginate.currentPage == paginate.totalPages }">
        <a class="pointer" (click)="pageSet(paginate.currentPage + 1)">
            <i class="fas fa-arrow-right"></i>
        </a>
    </li>
</ul>
